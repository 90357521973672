<template>
    <div :class="{clickable: !textLock}" @click="next" class="character-text" v-if="text">
        {{text}}
        <span class="triangle" v-if="!textLock && text">&#9655;</span>
    </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  export default {
    name: 'CharacterText',
    data () {
      return {
        index: 0,
      }
    },
    created () {
      window.addEventListener('keydown', e => {
          if (e.key === ' ') {
            this.next()
          }
        }
      )
    },
    computed: {
      ...mapState('character', ['textLock']),
      ...mapGetters('character', ['text']),
    },
    methods: mapActions('character', ['next']),
  }
</script>

<style scoped>
    .clickable {
        cursor: pointer;
    }
    .character-text {
        font-size: 1em;
        font-weight: 400;
        text-align: left;
        padding: 3rem 5rem;
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        width: 100%;
        min-height: 30%;
        color: #fff;
        background-color: rgba(17, 17, 17, 0.7);
    }
    .triangle {
        position: absolute;
        bottom: 3rem;
        right: 5rem;
        animation: blink 1s ease infinite;
    }
    @keyframes blink {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
</style>
