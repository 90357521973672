<template>
    <div class="controls">
        <div @click="display = !display" class="control">{{ display ? 'Hide UI' : 'Show Ui' }}</div>
        <transition name="fade">
            <div class="ui-box" v-if="display">
                <div class="element">{{speed}} BPM</div>
                <div @click="switchMode" class="control element">{{ mode }}</div>
                <div @click="switchSound" class="control element">{{ sound }}</div>
                <div @click="toggle" class="control element">{{ running ? 'Stop' : 'Start' }}</div>
                <div class="controls-group elemnent">
                    <span @click="speedUp" class="control control-sign" v-if="isManual">+</span>
                    <span @click="speedDown" class="control control-sign" v-if="isManual">-</span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { SOUNDS } from '../../api/metronome'
  import { MODE_AUTO_F, MODE_AUTO_S, MODE_MANUAL } from '../../store/metronome'

  const MODES = {}
  MODES[MODE_AUTO_S] = 'Auto S'
  MODES[MODE_AUTO_F] = 'Auto F'
  MODES[MODE_MANUAL] = 'Manual'

  const SOUNDS_NAMES = {}
  SOUNDS_NAMES[SOUNDS.WOODEN] = 'Wood'
  SOUNDS_NAMES[SOUNDS.HEARTHBEAT] = 'Heartbeat'
  SOUNDS_NAMES[SOUNDS.DRUM] = 'Drum'
  SOUNDS_NAMES[SOUNDS.DRYKICK] = 'Dry Kick'
  SOUNDS_NAMES[SOUNDS.DOUMDECK] = 'Doumbek'

  export default {
    name: 'Controls',
    computed: {
      ...mapState('metronome', ['speed', 'running']),
      isManual () {
        return this.$store.state.metronome.mode === MODE_MANUAL
      },
      sound () {
        return SOUNDS_NAMES[this.$store.getters['metronome/sound']] || '-'
      },
      mode () {
        return MODES[this.$store.state.metronome.mode] || MODES[MODE_MANUAL]
      },
    },
    data () {
      return {
        display: true,
      }
    },
    methods: {
      ...mapActions('metronome', ['start',]),
      ...mapMutations('metronome', ['stop', 'speedUp', 'speedDown', 'switchMode', 'switchSound']),
      toggle () {
        this.running ? this.stop() : this.start()
      },
    }
  }
</script>

<style lang="scss" scoped>
    .ui-box {
        margin: .3rem 0;
    }

    .controls {
        font-size: 16px;
        width: 100px;
        z-index: 5;
        font-weight: 700;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.7);
        text-shadow: #111 1px 1px 2px;
        position: absolute;
        margin: .3rem;
        padding: .3rem;
        box-sizing: border-box;
        top: 0;

        .element {
            margin-bottom: .3rem;
        }

        &-group {
            display: flex;
            justify-content: center;
        }

        .control {
            user-select: none;
            cursor: pointer;
            transition: transform .3s;

            &-sign {
                padding: 0 .5rem;
                font-size: 20px;
            }

            &:hover {
                transform: scale(1.3);
            }
        }
    }

    .fade {
        &-enter-active,
        &-leave-active {
            transition: all .5s;
        }

        &-enter-from,
        &-leave-to {
            opacity: 0;
        }
    }
</style>
