<template>
    <div class="image-wrapper">
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_13.png" v-if="isImage(STATUS.HAPPY,VERSION.NORMAL)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_14.png" v-if="isImage(STATUS.SMILE,VERSION.NORMAL)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_15.png" v-if="isImage(STATUS.EMBARRASSED,VERSION.NORMAL)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_16.png" v-if="isImage(STATUS.WORRIED,VERSION.NORMAL)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_17.png" v-if="isImage(STATUS.SAD,VERSION.NORMAL)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_18.png" v-if="isImage(STATUS.SURPRISED,VERSION.NORMAL)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_19.png" v-if="isImage(STATUS.SMUG,VERSION.NORMAL)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_20.png" v-if="isImage(STATUS.CONFOUNDED,VERSION.NORMAL)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_10_13.png" v-if="isImage(STATUS.HAPPY,VERSION.BLUSH)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_10_14.png" v-if="isImage(STATUS.SMILE,VERSION.BLUSH)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_10_15.png" v-if="isImage(STATUS.EMBARRASSED,VERSION.BLUSH)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_10_16.png" v-if="isImage(STATUS.WORRIED,VERSION.BLUSH)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_10_17.png" v-if="isImage(STATUS.SAD,VERSION.BLUSH)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_10_18.png" v-if="isImage(STATUS.SURPRISED,VERSION.BLUSH)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_10_19.png" v-if="isImage(STATUS.SMUG,VERSION.BLUSH)"/>
        </transition>
        <transition @after-leave="afterLeave" @before-leave="beforeLeave" name="fade">
            <img @click.prevent="change" alt="character" class="image"
                 src="~@/assets/character/STA01A_L_5_10_20.png" v-if="isImage(STATUS.CONFOUNDED,VERSION.BLUSH)"/>
        </transition>
    </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { STATUS, VERSION } from '../../store/character'

  export default {
    name: 'ImageSets',
    data () {
      return {
        STATUS,
        VERSION,
        show: true,
      }
    },
    computed: {
      ...mapState('character', ['version', 'status']),
      ...mapGetters('character', ['source']),
      isImage () {
        return (s, v) => s === this.status && v === this.version && this.show
      },
    },
    methods: {
      beforeLeave () {
        this.show = false
      },
      afterLeave () {
        this.show = true
      },
      change () {},
      ...mapActions('character', ['changeStatus']),
    },
  }
</script>

<style lang="scss" scoped>
    .image {
        transform: rotateY(180deg);
        max-width: 100%;

        &-wrapper {
            position: absolute;
            bottom: 0;
            width: 50%;
            display: flex;
        }
    }

    .fade {
        &-enter-active,
        &-leave-active {
            transition: all .5s;
        }

        &-enter-from, &-leave-to {
            opacity: 0;
        }
    }
</style>