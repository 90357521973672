<template>
    <div class="character">
        <ImageSets></ImageSets>
        <CharacterText></CharacterText>
    </div>
</template>

<script>

  import CharacterText from './CharacterText'
  import ImageSets from './ImageSets'

  export default {
    name: 'Character',
    components: {CharacterText, ImageSets},
  }
</script>

<style lang="scss" scoped>
    .character {
        z-index: 3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>
