<template>
    <div class="track">
        <span class="fence"></span>
        <transition-group name="fade">
            <div :key="circle.key" v-for="circle in circles">
                <Circle :delay="circle.delay" @sound="play"></Circle>
            </div>
        </transition-group>
    </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { metronome } from '../../api/metronome'
  import Circle from './Circle'

  export default {
    name: 'Track',
    components: {Circle},
    computed: {
      ...mapState('metronome', ['circles', 'running']),
      ...mapGetters('metronome', ['sound',]),
    },
    methods: {
      play () {
        this.$store.commit('metronome/shift')
        if (this.running) {
          metronome[this.sound].play()
        }
      },
    }
  }
</script>

<style scoped>
    .track {
        z-index: 3;
        width: 50%;
        position: absolute;
        height: 52px;
        overflow: visible;
        bottom: 10px;
        right: 0;
    }

    .fence {
        z-index: 10;
        background-color: #42b983;
        height: 50px;
        width: 16px;
        box-shadow: #111 0 0 4px 1px;
        position: absolute;
        left: 0;
    }

    .fade-enter-active, .fade-leave-active {
        transition: all .5s;
    }

    .fade-enter-from {
        opacity: 0;
    }

    .fade-leave-to {
        opacity: 0;
    }
</style>
