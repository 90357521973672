<template>
    <div class="choices">
        <div :key="item[1]" @click="choice(item[1])" class="choice" v-for="item in choices">
            {{item[0]}}
        </div>
    </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'Choices',
    computed: mapState('character', ['choices']),
    methods: mapActions('character', ['choice']),
  }
</script>

<style lang="scss" scoped>
    .choices {
        position: relative;
        z-index: 4;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 6rem 15rem;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.7);
        flex-direction: row;
        align-content: flex-start;
    }

    .choice {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #1e3d28;
        background-color: #42b983;
        color: #fff;
        margin: 1rem;
        width: 200px;
        height: 50px;
        transition: color .1s;
        cursor: pointer;

        &:hover {
            color: #1e3d28
        }
    }
</style>
