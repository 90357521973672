<template>
    <div class="hero" ref="app">
        <Slider v-if="!isCharacter"></Slider>
        <Controls v-if="withMetronome"></Controls>
        <transition name="fade">
            <Choices v-if="choices.length > 0"></Choices>
        </transition>
        <transition name="fade">
            <Character v-if="isCharacter"></Character>
        </transition>
        <transition name="fade">
            <Track v-if="isMetronome"></Track>
        </transition>
        <div @click="fullscreen" class="fullscreen">
            <svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%">
                <path d="m 10,16 2,0 0,-4 4,0 0,-2 L 10,10 l 0,6 0,0 z"></path>
                <path d="m 20,10 0,2 4,0 0,4 2,0 L 26,10 l -6,0 0,0 z"></path>
                <path d="m 24,24 -4,0 0,2 L 26,26 l 0,-6 -2,0 0,4 0,0 z"></path>
                <path d="M 12,20 10,20 10,26 l 6,0 0,-2 -4,0 0,-4 0,0 z"></path>
            </svg>
        </div>
    </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex'
  import { hero_1 } from '../character/text'
  import Character from '../components/character/Character'
  import Choices from '../components/character/Choices'
  import Controls from '../components/metronome/Controls'
  import Track from '../components/metronome/Track'
  import Slider from '../components/slider/Slider'

  export default {
    name: 'Hero',
    components: {Choices, Character, Track, Controls, Slider},
    data () {
      return {
        isFullScreen: false,
      }
    },
    created () {
      this.setTexts(hero_1)
      setTimeout(() => this.show(), 100)
    },
    computed: {
      ...mapState(['withMetronome']),
      ...mapState('metronome', {
        'isMetronome': 'display',
      }),
      ...mapState('character', {
        'isCharacter': 'display',
        'choices': 'choices',
      }),
    },
    methods: {
      ...mapMutations('character', ['show', 'setTexts']),
      fullscreen () {
        if (this.isFullScreen) {
          document.exitFullscreen()
          if (screen.orientation && screen.orientation.lock) {
            screen.orientation.lock('landscape')
          }
          this.isFullScreen = false
        } else {
          // window.screen.orientation.lock('landscape')
          this.$refs['app'].requestFullscreen()
          this.isFullScreen = true
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
    .hero {
        position: relative;
        margin: auto;
        width: 960px;
        height: 540px;
        background-color: #000;
    }

    .fullscreen {
        fill: #fff;
        filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.7));
        z-index: 5;
        width: 36px;
        position: absolute;
        right: 0;
        top: 0;
        margin: 1rem;
        transition: transform .3s;
        cursor: pointer;

        &:hover {
            transform: scale(1.5);
        }
    }

    .fade {
        &-enter-active,
        &-leave-active {
            transition: all .5s;
        }

        &-enter-from, &-leave-to {
            opacity: 0;
        }
    }
</style>
