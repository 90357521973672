<template>
    <div class="slide" ref="wrap">
        <img :src="url" :style="style" alt="slide" class="slide-image" ref="item">
    </div>
</template>

<script>
  import { nextTick } from 'vue'

  export default {
    name: 'ImageSlide',
    props: ['direction', 'url'],
    data () {
      const style = this.direction === 'up' ? {bottom: 0} : {top: 0}
      return {
        style,
        index: 0,
      }
    },
    computed: {},
    async created () {
      await nextTick()
      setTimeout(() => {
        let {height} = this.$refs['item']
        height = this.$refs['wrap'].offsetHeight - height
        if (this.direction === 'down') {
          this.style.top = height + 'px'
        } else {
          this.style.bottom = height + 'px'
        }
        setTimeout(() => this.$emit('done'), 11000)
      }, 500)
    },
    methods: {}
  }
</script>

<style lang="scss" scoped>
    .slide {
        height: 100%;
        width: 100%;
        z-index: 1;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        overflow: hidden;

        &-image {
            width: 100%;
            position: absolute;
            transition-duration: 8s;
            transition-property: top, bottom;
            transition-timing-function: linear;
        }
    }
</style>
