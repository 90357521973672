<template>
    <div class="slider">
        <ImageSlide :direction="direction"
                    :key="currentImage"
                    :url="currentImage"
                    @done="changeImage"
                    v-if="currentImage">
        </ImageSlide>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import ImageSlide from './ImageSlide'

  export default {
    name: 'Slider',
    components: {ImageSlide},
    computed: mapGetters('slider', ['currentImage', 'nextImage', 'direction',]),
    methods: mapActions('slider', ['changeImage']),
  }
</script>

<style scoped>
    .slider {
        color: #fff;
        position: relative;
        height: 100%;
        width: 100%;
    }
</style>
