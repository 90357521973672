<template>
    <div :style="{right, transitionDuration: delay + 'ms'}" class="circle"></div>
</template>

<script>
  export default {
    name: 'Circle',
    props: ['delay',],
    created () {
      setTimeout(() => this.right = 'calc(100% - 38px)', 100)
      setTimeout(() => this.$emit('sound'), this.delay + 100)
    },
    data () {
      return {right: 0}
    },
  }
</script>

<style scoped>
    .circle {
        border-radius: 30px;
        background-color: #42b983;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        right: 0;
        box-shadow: #111 0 0 3px 1px;
        width: 30px;
        height: 30px;
        position: absolute;
        transition-property: all;
        transition-timing-function: linear;
    }
</style>
